<template>
    <div class="evadetail">
        <!-- top -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>受检管理</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="goback1">受检评估</el-breadcrumb-item>
            <el-breadcrumb-item>详情页</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="changeBox">
            <div class="orgdata" @click="getOrgData">下载源数据</div>
            <!-- 上传数据  .zip -->
          <el-upload
            ref="upload"
            :limit="1" 
            action=""
            :show-file-list="false"
            :auto-upload="true"
            :before-upload="beforeUpload"
            :http-request="httprequest"
            :on-remove="remove"
          >
          <div class="orgdata" style="width:136px">重新上传标记X光图</div>
          </el-upload>
          <el-popconfirm
                confirm-button-text='确认'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                @confirm="restartCount"
                title="重新上传将会覆盖当前已标记图片"
              >
              <div class="orgdata" style="width:136px" slot="reference">重新计算</div>
            </el-popconfirm>
            
          <div class="orgdata" style="width:130px" @click="updateMark">更新算法计算结果</div>
         
        </div>
        <div class="baseInfo">
            <div class="title">
              <span class="blueTitle">基本信息</span>
              <span class="reportStatus" style="color:#FC5555" v-if="pageInfo.status =='处理中' ">{{pageInfo.status}}</span>
              <span class="reportStatus" style="color:#858585" v-if="pageInfo.status =='已处理' ">{{pageInfo.status}}</span>
            </div>
            <!-- 信息展示 -->
            <div class="infomation" v-if="topData">
                <span class="info-text">姓名：{{topData.examineName}}</span>
                <span class="info-text" v-if="(topData.gender!=null)&&(topData.gender==0)">性别：女</span>
                <span class="info-text" v-if="(topData.gender!=null)&&(topData.gender==1)">性别：男</span>
                <span class="info-text">年龄：{{pageInfo.age}}</span>
                <span class="info-text">身份证号：{{topData.cardId}}</span>
                <span class="info-text">手机号：{{topData.mobile}}</span>
                 <span class="info-text">受测机构：{{topData.orgName}}</span>
                <span class="info-text">上传时间：{{pageInfo.createTime}}</span>
            </div>
        </div>
        <div class="baseBottom">
            <!-- box-top -->
            <div style="display:flex;justify-content: space-between">
              <span></span>
              <el-popconfirm
                confirm-button-text='成功'
                cancel-button-text='失败'
                cancel-button-type="Primary"
                icon="el-icon-info"
                icon-color="#00cccc"
                @confirm="sendSMS"
                @cancel="nosendSMS"
                title="请选择发送短信的类型"
              >
                <span style="text-decoration:underline;color:#009dd9;font-size:16px;cursor:pointer" slot="reference">发送短信通知</span>
            </el-popconfirm>
            <span style="text-decoration:underline;color:#009dd9;font-size:16px;cursor:pointer" @click="startFailProp">不合格数据</span>
            </div>
            <div style="display:flex;margin-top: 40px;">
                <!-- 原始图像 -->
                <div>
                    <BlueBox :msg="msg1"/>
                    <div class="imgBox">
                        <div class="imginner" v-if="(pageInfo.xraysFileZ !=null)">
                        <viewer :images="[pageInfo.xraysFileZ]">
                            <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                                <img class="img" alt="" :src="pageInfo.xraysFileZ" >
                            </el-tooltip>
                        </viewer>
                        <div class="img-title">X光片（站立位全脊柱正位）</div>
                        </div>
                        <el-empty v-if="pageInfo.xraysFileZ===null" :image-size="252" description="暂无图片"></el-empty> 
                    </div>
                </div>
                <div style="margin-left:200px">
                    <BlueBox :msg="msg2"/>
                    <div class="imgBox">
                        <div class="imginner" v-if="(pageInfo.xraysFileMarkZ !=null)">
                        <viewer :images="[pageInfo.xraysFileMarkZ]">
                            <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                                <img class="img" alt="" :src="pageInfo.xraysFileMarkZ" >
                            </el-tooltip>
                        </viewer>
                        <div class="img-title">X光片（站立位全脊柱正位）</div>
                        </div>
                    </div>
                    <el-empty v-if="pageInfo.xraysFileMarkZ===null" :image-size="252" description="暂无图片"></el-empty> 
                </div>
            </div>
            <!-- 评估结果 -->
            <div class="eva-rsult">
                <span class="result-title">评估结果：</span>
                <div style="display:flex;" >
                <table border="1" style="width:362px;margin-top:14px;margin-bottom: 40px" cellspacing="0" cellpadding="8">
                          <tr style="text-align:center;color:#858585;font-size:14px;">
                              <td style="width:74px">项目</td>
                              <td style="width:180px">Cobb角</td> 
                          </tr>
                          <tr style="text-align:center;color:#858585;font-size:14px">
                              <td class="tdStyle">测量值</td>
                              <td class="tdStyle">{{cobb}}</td>
                          </tr>
                          <tr style="text-align:center;color:#858585;font-size:14px;">
                              <td class="tdStyle">标准范围</td>
                              <td class="tdStyle">0-10°</td>
                          </tr>
                </table>
                <!-- 评估结果 -->
                <div style=" margin-bottom: 40px">
                      <div class="eva-last">该受检者患有脊柱侧弯的概率：{{result}}</div>
                </div>
              
                 <!-- 发送报告 -->
                <div class="sendreport" @click="sendNotice">发送报告</div>
                </div>
            </div>
            <!-- 更新计算结果弹窗 -->
            <el-dialog
              title="更新计算结果"
              :showClose="false"
              :visible.sync="showUpdate"
              width="390px"
              center>
            <!-- 内容区 -->
            <el-form ref="appendForm" :model="upForm" label-width="100px">
                <el-form-item label="cobbAngle">
                  <el-input type="number" v-model="upForm.angle" style="width:200px !important"></el-input>
                </el-form-item>
              </el-form>
              <!-- footer -->
              <div style="width:440px;display:flex;justify-content:space-around;margin-top:40px;margin-left:-46px">
                <div style="width:80px;height:32px;font-size:16px;color:#fff;cursor:pointer;background-color: #00cccc;line-height: 32px; border-radius:4px;text-align: center" @click="cancelUP">取消</div>
                <div style="width:80px;height:32px;font-size:16px;color:#fff;cursor:pointer;background-color: #00cccc;line-height: 32px; border-radius:4px;text-align: center" @click="sureUP">确认</div>
              </div>
            </el-dialog>
            <!-- 不合格数据弹窗 -->
            <el-dialog
              title="不合格数据反馈"
              show-close
              :visible.sync="showErro"
              @close="closeErroPro"
              width="390px"
              center>
            <!-- 内容区 -->
            <el-form ref="appendForm" :model="erroForm" label-width="100px">
                <el-form-item label="错误体位">
                    <el-input  autosize size="small" disabled v-model="erroForm.title" style="width:200px !important"></el-input>
                </el-form-item>
                <el-form-item label="错误详情">
                  <el-input type="textarea" autosize v-model="erroForm.detail" style="width:200px !important"></el-input>
                </el-form-item>
              </el-form>
              <!-- footer -->
                <div class="send-footer">
                  <div style="font-size:30px;cursor: pointer;"></div>
                  <div class="send" @click="sendFailReports">发送报告</div>
                </div>
            </el-dialog>
        </div>
    </div>
  </template>
  
  <script>
  import {viewReportData,downloadOrgData,uploadReportImage,sendReport,sendFailReport,getBaseInfo,sendSMS,nosendSMS,getNewBackImg,updateResult,updateXMark,updateXResult,updateCobbAngle} from '@/api/userManage.js'
  import BlueBox from '@/components/other/blueBox.vue'
  import axios from 'axios'
  import {getSignature} from '@/api/order.js'
  import {getFolderLastName} from '@/utils/uploadfun.js'
  export default {
    beforeRouteLeave(to,from,next){
      if((to.name === 'Cobb')||(to.name==='SpineEvaluation')){
        to.meta.keepAlive = true;
      } 
      next();
    },
    components:{
        BlueBox
    },
        data(){
          
          return{
            newId1:null,
            newId2:null,
            topData:null,//表头数据
            id:'',
            pageId:'',
            pageInfo:'',
            fatherInfo:{
              name:null,
              gender:null,
              mobile:null,
              cardId:null,
            },
            cobb:'-',
            result:'-',
            resultnew:'-',
            upFile:new FormData(),
            newBackImg:null,//背部图像二次计算图
            showUpdate:false,//更新计算结果弹窗
            shoulderValue:'-',//高低肩值
            shoulderResult:'-',
            upForm:{angle:null},
            erroForm:{
              title:'X光检测',
              detail:null
            },
            showErro:false,//不合格数据弹窗
            msg1:"原始图片",
            msg2:"计算图片",
            signa:null,//签名
          }
        },
        created(){
          if(this.$route.query){
                this.newId1 = this.$route.query.rid;
                this.getTopData(this.newId1);
                //加载页面数据
                this.newId2 = this.$route.query.id;
                this.getInfoById(this.newId2);
          }
        },
        mounted(){
            this.getSigna();//获取签名
        },
       
        methods:{
              //面包屑返回
              goback1(){
                this.$router.go(-1);
              },
              //表头数据
              getTopData(id){
                getBaseInfo({
                      id:id
                  }).then(res=>{
                    if(res.data.status == 200){
                        this.topData=res.data.data;
                    }else{
                        this.$message.error(res.data.message);
                    } 
                  }).catch(res=>{
                      this.$message.error(res);
                  })
              },
            //获取签名
              getSigna(){
                  getSignature({}).then(res=>{
                      if(res.data.status == 200){
                          this.signa = res.data.data;
                      }
                    }).catch(erro=>{
                        console.log("catch-erro",erro)
                    });
               },
              //加载页面数据
              getInfoById(id){
                viewReportData({
                  id:id
                }).then(res=>{
                  if(res.data.status == 200){
                        //console.log("id测试",res.data)
                        this.pageInfo=res.data.data;
                        this.pageId = res.data.data.id;
                        this.cobb = res.data.data.cobbAngle;
                        this.result = res.data.data.riskResult; 
                        this.resultnew = res.data.data.examineResult;
                        this.shoulderValue = res.data.data.unevenShoulder;
                        this.shoulderResult = res.data.data.shoulderResult;
                        this.fatherInfo.status =  res.data.data.status;
                  }else{
                      this.$message.error(res.data.message);
                  }
                }).catch(res=>{
                   this.$message.error(res);
                })
              },
              //下载原始数据  64id 有数据 /二进制流下载文件方法
              getOrgData(){
                const loading = this.$loading({
                  lock: true,
                  text: '下载中',
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)'
                  });
  
                   downloadOrgData({
                  id:this.pageId
                  }).then(res => {
                        if (!res || !res.data) {
                          this.$message.error('暂无');
                        }
                        if (res.data.type === 'application/json') {
                                const reader = new FileReader()
                                reader.readAsText(res.data)
                                reader.onload = e => {
                                const resData = JSON.parse(reader.result)
                                if(resData.status!=200){
                                    loading.close();
                                    this.$message.error(resData.message)
                                }
                            }
                        }
                        else{
                            const { data, headers } = res
                            const newHeader = decodeURI(headers['content-disposition']);//进行解码  decodeURI()方式解码
                            //console.log("头文件",newHeader.split(';')[1].split('=')[1]);
                            const fileName = newHeader.split(';')[1].split('=')[1];//提取文件名称
                            const blob = new Blob([data], { type:'application/zip'})
                            let dom = document.createElement('a')
                            let url = window.URL.createObjectURL(blob)
                            dom.href = url
                            dom.download = decodeURI(fileName)
                            dom.style.display = 'none'
                            document.body.appendChild(dom)
                            dom.click();
                            dom.parentNode.removeChild(dom)
                            window.URL.revokeObjectURL(url)
                            loading.close();
                        }
                          
              }).catch((res) => {
                      this.$message.error(res);
                    });          
              },
              //重新上传图片
              beforeUpload(file){
                        
                        var fileType=file.name.substring(file.name.lastIndexOf('.'));
                        if((fileType === '.jpg') || (fileType === '.png') || (fileType === '.jpeg')){
                          return fileType ; 
                        }else{
                          this.$message({
                                type:'warning',
                                message:'上传类型只能是图片'
                            })
                            return !fileType ;
                        }            
                        
                        
              },
              httprequest(par){
                    var uploadFile = par.file;
                    var filename = par.file.name;
                    var type  = par.file.type;
                    var postfix =getFolderLastName(filename);//文件后缀 
                    var folder =this.signa.folder; 
                    const key =`${folder}.${postfix}`;// 文件名+后缀//文件名+后缀//不加斜杠
                    let ossUrl = this.signa.host;//填写自己OSS服务器的地址
                    var formData = new FormData();
                    formData.append('OSSAccessKeyId', this.signa.accessId);
                    formData.append('policy', this.signa.policy);
                    formData.append('Signature', this.signa.signature);
                    formData.append('key', key);//上传的文件路径
                    formData.append('success_action_status', 200); // 指定返回的状态码
                    formData.append('x-oss-content-type', type);
                    formData.append('file',uploadFile);
                // console.log('last',formData);
                    axios.post(ossUrl, formData).then((res) => {
                        //console.log("res",res);
                        //上转成功后更新
                        updateXMark({
                            id:this.pageId,
                            xrayImage:key
                        }).then(res=>{
                        if(res.data.status==200){
                            this.$message({
                            message: '操作成功！',
                            type: 'success'
                            });
                            this.$refs.upload.clearFiles(); //上传成功后要对浏览器进行清除后才能再次上传
                            this.getTopData(this.newId1);
                            this.getInfoById(this.newId2);
                            this.getSigna();//再次获取签名
                    }else{
                        this.$message.error(res.data.message);
                    }
                    })

                })   
             },
            remove(){},//移除以上传的--暂不需要
            //发送报告
            sendNotice(){
              sendReport({
                id:this.pageId
              }).then(res=>{
                  if(res.data.status == 200){
                  this.getInfoById(this.newId2);//更新页面数据 
                  this.$message({
                  message: '下发报告成功！',
                  type: 'success'
                    });
                }else{
                  this.$message.error(res.data.message);
                }
              }).catch(res=>{
                this.$message.error(res);
              })
            },
            //发送短信--不合格报告
            startFailProp(){
              this.showErro = true;
            },
            //关闭弹窗 清空
            closeErroPro(){
              this.erroForm.detail = null;
            },
            //发送错误信息订阅
            sendFailReports(){
              if(this.erroForm.detail!=null){
                sendFailReport({
                reportDataDetailId:this.pageId,
                xraysErrorFeedback:this.erroForm.detail
              }).then(res=>{
                if(res.data.status===200){
                  this.getInfoById(this.newId2);//更新页面数据 
                  this.$message({
                    message: '发送成功！',
                    type: 'success'
                    });
                    this.showErro = false;
                    this.closeErroPro();//clear
                }else{
                  this.$message.error(res.data.message);
                }
              })
              }else{
                this.$message.error("请先填写错误信息！");
              }
            },
            //发送短信通信通知
            sendSMS(){
              sendSMS({
                id:this.pageId
              }).then(res=>{
                  if(res.data.status == 200){
                    this.getInfoById(this.newId2);//更新页面数据 
                    this.$message({
                    message: '发送成功！',
                    type: 'success'
                    });
                }else{
                  this.$message.error(res.data.message);
                }
              }).catch(res=>{
                this.$message.error(res);
              })
            },
            nosendSMS(){
              //console.log("不发送");//nosendSMS
              nosendSMS({
                id:this.pageId
              }).then(res=>{
                  if(res.data.status == 200){
                    this.getInfoById(this.newId2);//更新页面数据 
                    this.$message({
                    message: '发送成功！',
                    type: 'success'
                    });
                }else{
                  this.$message.error(res.data.message);
                }
              }).catch(res=>{
                this.$message.error(res);
              })
            },
            //重新计算
            restartCount(){
              //重新计算
              updateXResult({id:this.pageId}).then(res=>{
                if(res.data.status==200){
                  this.$message({
                    message: '操作成功！',
                    type: 'success'
                    });
                    this.getTopData(this.newId1);
                    this.getInfoById(this.newId2);
                }else{
                  this.$message.error(res.data.message);
                }
              })  
            },
            //更新算法计算结果
            updateMark(){
              this.showUpdate = true;
              //回显
              this.upForm.angle = this.cobb;
            },
            //取消更新
            cancelUP(){
              this.showUpdate = false;
              this.upForm.angle = null;
            },
            //确认
            sureUP(){
                updateCobbAngle({
                  id:this.newId2,
                  cobbAngle:this.upForm.angle,
              }).then(res=>{
                if(res.data.status==200){
                  this.$message({
                    message: '更新成功！',
                    type: 'success'
                    });
                    this.getTopData(this.newId1);
                    this.getInfoById(this.newId2);
                    this.showUpdate = false;
                }else{
                  this.$message.error(res.data.message);
                }
              })
            }
     }
  }
  </script>
  
  <style lang="stylus" scoped>
  //deep
  /deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
      font-weight: bold !important;
    }
  /deep/.send-footer{
    width:100%;
    height: 32px;
    display:flex;
    align-items: center;
    justify-content: center
  }
  /deep/.send{
    width: 160px ;
    cursor pointer;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background-color: #00CCCC;
    color: #fff;
    margin-left: 20px;
    font-size: 14px;
    border-radius: 24px;
  }
  //component style 
  .blueTitle
    font-size: 16px 
    color: #353535
    line-height: 28px 
    position: relative
    &::before
      width: 3px
      height: 18px
      content: ""
      position: absolute
      border-radius: .03rem
      left: -10px
      bottom:4px
      background-color: #00CCCC
  
  .eva-last{
      margin-left: 38px;
      font-size: 16px;
      margin-top: 110px;
      color: #353535;
      opacity .8
  }
  .sendreport
    width: 116px 
    height: 32px 
    position: absolute
    right: 60px
    background-color: #00cccc
    color: #fff
    text-align: center
    line-height: 32px  
    font-size:14px
    margin-top: 110px;
    cursor pointer
  
  .sendsms
    width: 116px 
    height: 32px 
    background-color: #00cccc
    color: #fff
    text-align: center
    line-height: 32px  
    font-size:14px
    margin-top: 110px;
    margin-left: 0.2rem
    cursor pointer
  
  
  .org-img
    margin-top: 34px 
    font-size: 16px  
    color: #353535
  .data-img
    margin-top: 54px 
    font-size: 16px  
    color: #353535
  
  
  //normal 
  .evadetail
    overflow hidden
    .changeBox
      margin-top: 18px
      display: flex
      .orgdata
          width: 116px 
          cursor pointer
          height: 32px
          line-height: 32px
          text-align: center
          background-color: #00CCCC
          color: #fff
          margin-right: 12px
          font-size: 14px
    .baseInfo
      width: 100%
      height: 120px
      background-color: #FFFFFF
      padding: 24px 28px 0 25px
      box-sizing: border-box
      margin-top: 14px
      .title
        display: flex
        justify-content: space-between
        align-items: center
        .reportStatus
          color: #FC5555
          font-size: 22px
          opacity .8
      .infomation
        margin-left: 0px
        margin-top: 22px 
        display: flex
        justify-content: space-around
        flex-wrap: wrap
        .info-text
          font-size: 14px
          color: #858585
    .baseBottom
      width: 100%
      box-sizing: border-box
      padding: 24px 28px 0 25px 
      background-color: #fff
      margin-top: 14px
      .imgBox
        margin-left: 8px 
        margin-top: 26px 
        display: flex
        flex-wrap: wrap
        .imginner
          width: 252px 
          margin-right:50px
          margin-bottom: 26px
          text-align: center
          .img
            width: 252px
            height: 354px 
            object-fit: cover
          .img-title
            font-size: 16px 
            color: #353535
            opacity .8
            margin-top: 26px
      .eva-rsult
        margin-top: 38px 
       
        .result-title
          font-size: 16px 
          color: #353535
          opacity .8
        
  </style>>